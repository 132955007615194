import React, { useState, useContext } from 'react';
import { Card, Badge } from 'react-bootstrap';
import { IoStar } from 'react-icons/io5';
import { TbShoppingCartPlus, TbShoppingCartCheck, TbHeartPlus, TbHeartCheck } from "react-icons/tb";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from 'axios';
import { CartContext } from '../../Utils/CartContext';
import { WishlistContext } from '../../Utils/WishlistContext';
import { Link, useNavigate } from 'react-router-dom';
import './ProductCard.css';
import SweetAlert2 from 'react-sweetalert2';
import SecureLS from "secure-ls";

const ProductCard = ({ item, baseUrl, wishlist, cart }) => {
    const ls = new SecureLS({ encodingType: 'aes' });
    const navigate = useNavigate();
    const { addToCart } = useContext(CartContext);
    const { addToWishlist, removeFromWishlist } = useContext(WishlistContext);

    const [isAddedToCart, setIsAddedToCart] = useState(false);
    const [showLoadingIconCart, setShowLoadingIconCart] = useState(false);
    const [isAddedToWishlist, setIsAddedWishlist] = useState(wishlist === 1);
    const [showLoadingIconWishlist, setShowLoadingIconWishlist] = useState(false);
    const [swalProps, setSwalProps] = useState({});
    const selectedSize = useState(item.price[0].weight);
    const quantity = 1;

    const handleAddToCart = async () => {
        setShowLoadingIconCart(true);
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        const selectedPriceDetails = item.price[0];

        // console.log('id:',sessionId, 'email:',username);
        const success = username
            ? await addToCart('na', username, { selectedProdCode: selectedPriceDetails, quantity: quantity })
            : await addToCart(sessionId, 'na', { selectedProdCode: selectedPriceDetails, quantity: quantity });

        if (success) {
            // console.log(`Added ${item.name} to cart`);
            setShowLoadingIconCart(false);
            setIsAddedToCart(true);
        } else {
            console.error('Failed to add to cart');
            setShowLoadingIconCart(false);
        }
    };


    const handleAddToWishlist = async () => {
        setShowLoadingIconWishlist(true);
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        const selectedPriceDetails = item.price[0];
        const success = username
            ? await addToWishlist('na', username, { ...item, selectedProdCode: selectedPriceDetails.prodcode })
            : await addToWishlist(sessionId, 'na', { ...item, selectedProdCode: selectedPriceDetails.prodcode });
        if (success) {
            // console.log(`Added ${item.name} to wishlist`);
            setShowLoadingIconWishlist(false);
            setIsAddedWishlist(true);
        } else {
            console.error('Failed to add to wishlist');
            setShowLoadingIconWishlist(false);
        }
    };


    const handleRemoveFromWishlist = async () => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        const selectedPriceDetails = item.price[0];
        const success = username
            ? await removeFromWishlist('na', username, { ...item, selectedProdCode: selectedPriceDetails.prodcode })
            : await removeFromWishlist(sessionId, 'na', { ...item, selectedProdCode: selectedPriceDetails.prodcode });
        if (success) {
            console.log(`Removed ${item.name} from wishlist`);
        } else {
            console.error('Failed to remove from wishlist');
        }
    };

    const generateSlug = (name) => {
        return name.replace(/ /g, '-');
    }

    return (
        <>
            <Card className="custom-card bg-transparent mx-2">
                <a href={`/product/${generateSlug(item?.name)}`} state={{ item }}>
                    <Card.Img
                        className='product-image'
                        src={baseUrl + item?.images[0]}
                    />
                </a>
                <div className='addToWishlist'>
                    {showLoadingIconWishlist ? (
                        <AiOutlineLoading3Quarters size={20} color='#EC1F29' className='rotate' />
                    ) : isAddedToWishlist || wishlist == 1 ? (
                        <TbHeartCheck size={20} color='#CC0001' onClick={handleRemoveFromWishlist} />
                    ) : (
                        <TbHeartPlus size={20} color='#CC0001' onClick={handleAddToWishlist} />
                    )}
                </div>
                <Card.Body className='px-0'>
                    <Card.Title className='prod-name elipses'>
                        <a href={`/product/${generateSlug(item.name)}`} className='text-decoration-none text-black fw-medium' state={{ item }}>
                            {item?.name}
                        </a>
                    </Card.Title>
                    <Card.Text className='brand-name'>Karstar</Card.Text>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div>
                            <div>
                                <IoStar color='#FFD700' /> {item.price[0].average_rating}
                                {item.price[0].delivered_sales_count > 0 && (
                                    <>
                                        {' | '}
                                        <Badge bg="secondary">{item.price[0].delivered_sales_count + ' ' + 'sold'}</Badge>
                                    </>
                                )}
                            </div>
                            <div>
                                <span className='me-2' style={{ fontSize: '0.8rem', fontWeight: 'bold' }} >₹ {item.price[0].discountedPrice}</span>
                                <span style={{ textDecoration: 'line-through', color: 'grey', fontSize: '0.9rem' }} >₹ {item.price[0].basePrice}</span>
                            </div>
                        </div>
                        <div className='addToCart'>
                            {showLoadingIconCart ? (
                                <AiOutlineLoading3Quarters size={20} color='#EC1F29' className='rotate' />
                            ) : isAddedToCart || cart == 1 ? (
                                <TbShoppingCartCheck size={20} color='#28A745' />
                            ) : (
                                <TbShoppingCartPlus size={20} color='#28A745' onClick={handleAddToCart} />
                            )}
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <SweetAlert2 {...swalProps}
                onConfirm={result => {
                    navigate('/cart')
                }}
            />
        </>
    );
};

export default ProductCard;
