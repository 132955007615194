import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import './Contactus.css'
const Contactus = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }
    if (!formData.phone) newErrors.phone = 'Phone is required';
    if (!formData.subject) newErrors.subject = 'Subject is required';
    if (!formData.message) newErrors.message = 'Message is required';
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      setSubmitted(true);
      // Process form data here (e.g., send to server)
      console.log('Form data:', formData);
      // Reset form after submission
      setFormData({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
      });
    }
  };

  return (
    <div className='contactusView mt-3'>
      <div className='breadcrumb-container container'>
        <div className='breadcrumb'>
          <a href='/' className='breadcrumb-item text-black'>Home</a>
          <div className='breadcrumb-item text-black'>Contact us Us</div>
        </div>
      </div>
      <section className="p-b-10">
        <Container>
          <section className="bg-img1 text-center fw-bold p-lr-15 p-tb-92">
            <h2 className="ltext-103 text-center cl4">
              Contact Us
            </h2>
          </section>
          <div className="d-flex flex-wrap" style={{ paddingTop: '10px' }}>
            <Col md={12} className="border p-3" style={{ borderRadius: '5px' }}>
              <p className="text-center text-black">Send us an e-Mail</p>
              {submitted && <Alert variant="success">Sent an email successfully!</Alert>}
              <Form onSubmit={handleSubmit} className="form-submit">
                <Row>
                  <Col md={6} className="form-group">
                    <Form.Label className="text-black">Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      placeholder="Enter your name"
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                  </Col>
                  <Col md={6} className="form-group">
                    <Form.Label className="text-black">e-Mail</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder="Enter your email Id"
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} className="form-group">
                    <Form.Label className="text-black">Phone</Form.Label>
                    <Form.Control
                      type="tel"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      placeholder="Enter your phone number"
                      isInvalid={!!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                  </Col>
                  <Col md={6} className="form-group">
                    <Form.Label className="text-black">Subject</Form.Label>
                    <Form.Control
                      type="text"
                      name="subject"
                      value={formData.subject}
                      onChange={handleInputChange}
                      placeholder="Enter subject of your email"
                      isInvalid={!!errors.subject}
                    />
                    <Form.Control.Feedback type="invalid">{errors.subject}</Form.Control.Feedback>
                  </Col>
                </Row>
                <Form.Group>
                  <Form.Label className="text-black">Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    rows={3}
                    placeholder="Enter the message you want to send us"
                    isInvalid={!!errors.message}
                  />
                  <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
                </Form.Group>
                <Button type="submit" className="btn btn-danger mt-4 align-items-end w-25">
                  Send an e-Mail
                </Button>
              </Form>
            </Col>
          </div>
          <div className="d-flex flex-wrap border my-5">
            <Col md={8} className="p-3">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3882.4884130800547!2d77.09824247391141!3d13.319866207149554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae25c3bbdbb6f1%3A0xb01e8565394a03bd!2sStarlet%20corporation!5e0!3m2!1sen!2sin!4v1686294913032!5m2!1sen!2sin"
                width="100%"
                height="400"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>
            <Col md={4} className="d-flex flex-column p-3">
              <div className="d-flex w-100 mb-3">
                <span className="fs-18 text-center mr-2">
                  <i className="lnr lnr-map-marker text-dark"></i>
                </span>
                <div>
                  <h6 className="m-0 text-dark">Address</h6>
                  <p className="m-0 text-dark">
                    <span className="fw-bold">Headoffice </span>: Starlet Corporation <br />
                    Ray Cel Complex, Devaraj URS Road, Opp. Vidyaniketana High School,
                    <br /> Saraswati Puram Main Road
                    <br /> Tumakuru, Karnataka 572105.
                  </p>
                </div>
              </div>
              <div className="d-flex w-100 mb-3">
                <span className="fs-18 text-center mr-2">
                  <i className="lnr lnr-phone-handset text-dark"></i>
                </span>
                <div>
                  <h6 className="m-0 text-dark">Lets Talk</h6>
                  <p className="m-0 text-dark">+91 8884855855</p>
                </div>
              </div>
              <div className="d-flex w-100 mb-3">
                <span className="fs-18 text-center mr-2">
                  <i className="lnr lnr-bubble text-dark"></i>
                </span>
                <div>
                  <h6 className="m-0 text-dark">WhatsApp Us on</h6>
                  <p className="m-0 text-dark">
                    <a href="https://wa.me/919036113046" target="_blank" rel="noopener noreferrer">
                      +91 9036113046
                    </a>
                  </p>
                </div>
              </div>
              <div className="d-flex w-100">
                <span className="fs-18 text-center mr-2">
                  <i className="lnr lnr-envelope text-dark"></i>
                </span>
                <div>
                  <h6 className="m-0 text-dark">Sales Support</h6>
                  <p className="m-0 text-dark">info@hotstarr.co.in</p>
                </div>
              </div>
            </Col>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Contactus;
