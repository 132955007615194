import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Breadcrumb, Col, Container, Row, Dropdown } from 'react-bootstrap';
import ProductCard from '../../components/ProductCard/ProductCard';
import './Product.css';

function Product() {
  const baseUrl = 'https://karstar.org/';

  const formatTitle = (slug) => {
    return slug
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const [products, setProducts] = useState([]);
  const [originalProducts, setOriginalProducts] = useState([]); // Store the original list
  const [loading, setLoading] = useState(true); // Add a loading state
  const [sortOption, setSortOption] = useState('default');
  const [sortOptiontype, setSortOptiontype] = useState('All');

  useEffect(() => {
    const sessionId = sessionStorage.getItem('sessionId');
    const username = sessionStorage.getItem('username');
    if (sessionId || username) {
      getAllproducts(sessionId, username);
    }
  }, []);

  const getAllproducts = async (sessionId, username) => {
    try {
      const response = await axios.get(`https://karstar.org/api/client_api/products/allProducts/?ipadd=${sessionId}&email=${username}`);
      setProducts(response.data);
      setOriginalProducts(response.data); // Store the original products
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Set loading to false after the fetch is complete
    }
  };

  const handleSortChange = (option, name) => {
    setSortOption(option);
    setSortOptiontype(name);
    let sortedProducts;

    if (option === 'price-asc') {
      sortedProducts = [...products].sort((a, b) => a.prod_Price - b.prod_Price);
    } else if (option === 'price-desc') {
      sortedProducts = [...products].sort((a, b) => b.prod_Price - a.prod_Price);
    } else if (option === 'best-selling') {
      sortedProducts = [...products].sort((a, b) => b.order_count - a.order_count);
    } else if (option === 'remove-filters') {
      sortedProducts = originalProducts; // Reset to the original products
    }

    setProducts(sortedProducts);
  };

  return (
    <div>
      <Container>
        <section className='ProductCategoryView'>
          <div className='d-flex align-items-center justify-content-between'>
            <div className='breadcrumb-container py-5'>
              <div className='breadcrumb mb-0'>
                <a to='/' className='breadcrumb-item text-black'>Home</a>
                <span className='breadcrumb-separator'>/</span>
                <div className='breadcrumb-item text-black active'>All Products</div>
              </div>
            </div>

            <Dropdown className="py-5">
              <Dropdown.Toggle style={{border:'1px solid #cc0000', background:'#fff', color:'#CC0000', borderRadius:'1rem'}} id="dropdown-basic">
                Sort by {sortOptiontype}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleSortChange('price-asc', 'Price Low to High')}>Price: Low to High</Dropdown.Item>
                <Dropdown.Item onClick={() => handleSortChange('price-desc', 'Price High to Low')}>Price: High to Low</Dropdown.Item>
                <Dropdown.Item onClick={() => handleSortChange('best-selling', 'Best Selling')}>Best Selling</Dropdown.Item>
                <Dropdown.Item onClick={() => handleSortChange('remove-filters', 'All')}>Remove Filters</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <Row className="g-0">
            {loading ? (
              <Col className="text-center">Loading...</Col>
            ) : (
              products && products.map((item, index) => (
                <Col xs={6} md={6} lg={2} key={index}>
                  <ProductCard item={item} baseUrl={baseUrl} />
                </Col>
              ))
            )}
          </Row>
        </section>
      </Container>
    </div>
  );
}

export default Product;
