import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Policies.css'; // Custom CSS for the font style

const ExchangeReturnPolicy = () => {
  return (
    <Container className="exchange-return-policy sectionView">
      <Row>
        <Col>
          <h1 className="text-center">Exchange and Return Policy</h1>
          <p className="text-center"><em>Last updated: 1st September 2024</em></p>
          
          <h2>1. No Returns or Exchanges</h2>
          <p>All sales made on Karstar ("we," "us," "our") are considered final. We do not accept returns or exchanges through our website.</p>
          
          <h2>2. In-Person Returns</h2>
          <p>If you wish to return or exchange a product, please visit the nearest store for assistance. Store locations and contact information can be found on our website.</p>
          
          <h2>3. Defective or Damaged Products</h2>
          <p>If you receive a defective or damaged product, please contact our customer support team immediately after receiving the item. We will provide instructions on how to proceed.</p>
          
          <h2>4. Contact Us</h2>
          <p>If you have any questions or concerns about our Exchange and Return Policy, please contact us from the contact us page.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default ExchangeReturnPolicy;
