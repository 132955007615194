import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ReactImageMagnify from 'react-image-magnify';
import { CartContext } from '../../Utils/CartContext';
import { WishlistContext } from '../../Utils/WishlistContext';
import axios from 'axios';
import { IoAddSharp, IoRemoveSharp, IoStar } from 'react-icons/io5';
import { AiOutlineHome, AiOutlineLoading3Quarters, AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { TbHeartCheck, TbHeartPlus } from 'react-icons/tb';
import SweetAlert2 from 'react-sweetalert2';
import DOMPurify from 'dompurify';
import { Badge, Button, ButtonGroup, ButtonToolbar, Card, Col, Row } from 'react-bootstrap';
import RelatedProducts from '../../components/Products/RelatedProducts';
import RecentProducts from '../../components/Products/RecentProducts';
import SecureLS from "secure-ls";

function ProductDetails() {
    const ls = new SecureLS({ encodingType: 'aes' });
    const { productName } = useParams();
    const product_name = productName.replace(/-/g, ' ');
    const base_url = 'https://karstar.org/';

    const [item, setItem] = useState(null);
    const [selectedVariant, setSelectedVariant] = useState(null);
    const [mainImage, setMainImage] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const { addToCart } = useContext(CartContext);
    const { addToWishlist, removeFromWishlist } = useContext(WishlistContext);

    const [isAddedToCart, setIsAddedToCart] = useState(false);
    const [showLoadingIconCart, setShowLoadingIconCart] = useState(false);
    const [isAddedToWishlist, setIsAddedWishlist] = useState(false);
    const [showLoadingIconWishlist, setShowLoadingIconWishlist] = useState(false);
    const [swalProps, setSwalProps] = useState({});
    const [selectedSize, setSelectedSize] = useState(null);

    useEffect(() => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        fetchProduct(sessionId, username, product_name);
    }, [product_name]);

    const fetchProduct = async (sessionId, username, product_name) => {
        try {
            const response = await axios.get(`https://karstar.org/api/client_api/products/getproductdetails/index.php`, {
                params: {
                    name: product_name,
                    email: username || 'na',
                    ipadd: sessionId || 'na'
                }
            });
            setItem(response.data);
            setSelectedVariant(response.data[0]);
            setMainImage(base_url + response.data[0].images[0]);
            setSelectedSize(response.data[0].price[0].weight + 'kg'); // Default size
            setIsLoading(false);
        } catch (err) {
            setError(err.message);
            setIsLoading(false);
        }
    };

    const handleThumbnailClick = (image) => {
        setMainImage(base_url + image.replace('uploads', 'media').replace('w3prouploads', 'karstar'));
    };

    const handleQuantityChange = (change) => setQuantity(Math.max(1, quantity + change));

    const handleAddToCart = async () => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        const selectedPriceDetails = selectedVariant.price.find(p => p.weight + 'kg' === selectedSize);
        console.log(selectedPriceDetails);

        // console.log('id:',sessionId, 'email:',username);
        const success = username
            ? await addToCart('na', username, { selectedProdCode: selectedPriceDetails, quantity })
            : await addToCart(sessionId || 'na', 'na', { selectedProdCode: selectedPriceDetails, quantity });

        if (success) {
            console.log(`Added ${selectedVariant.name} to cart`);
        } else {
            console.error('Failed to add to cart');
        }
    };

    const handleAddToWishlist = async () => {
        setShowLoadingIconWishlist(true);
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        const selectedPriceDetails = selectedVariant.price.find(p => p.weight + 'kg' === selectedSize);
        const success = username
            ? await addToWishlist('na', username, { ...item, selectedProdCode: selectedPriceDetails.prodcode })
            : await addToWishlist(sessionId || 'na', 'na', { ...item, selectedProdCode: selectedPriceDetails.prodcode });
        if (success) {
            console.log(`Added ${selectedVariant.name} to wishlist`);
            setShowLoadingIconWishlist(false);
        } else {
            console.error('Failed to add to wishlist');
            setShowLoadingIconWishlist(false);
        }
    };

    const handleRemoveFromWishlist = async () => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = ls.get('userEmail');
        const selectedPriceDetails = selectedVariant.price.find(p => p.weight + 'kg' === selectedSize);
        const success = username
            ? await removeFromWishlist('na', username, { ...item, selectedProdCode: selectedPriceDetails.prodcode })
            : await removeFromWishlist(sessionId || 'na', 'na', { ...item, selectedProdCode: selectedPriceDetails.prodcode });
        if (success) {
            console.log(`Removed ${selectedVariant.name} from wishlist`);
        } else {
            console.error('Failed to remove from wishlist');
        }
    };

    const generateSlug = (name) => {
        return name.toLowerCase().replace(/ /g, '-');
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const sanitizeHTML = (html) => {
        return DOMPurify.sanitize(html);
    };

    const handleSizeChange = (size) => setSelectedSize(size);

    const handlevariantChange = (item) => {
        setSelectedVariant(item);
        setMainImage(base_url + item.images[0]);
        setSelectedSize(item.price[0].weight + 'kg'); // Default size

        const newUrl = `/product/${generateSlug(item.name)}`;
        window.history.replaceState(null, '', newUrl);
    }
    const Discount = ((selectedVariant.price.find(p => p.weight + 'kg' === selectedSize)?.basePrice - selectedVariant.price.find(p => p.weight + 'kg' === selectedSize)?.discountedPrice) / selectedVariant.price.find(p => p.weight + 'kg' === selectedSize)?.basePrice) * 100;
    const DiscountNearestPercentage = Math.round(Discount);


    return (
        <div className='pb-5 sectionView  mt-3'>
            <div className="container">
                <div className='breadcrumb-container pb-2'>
                    <div className='breadcrumb'>
                        <a to='/' className='breadcrumb-item text-black me-2'><AiOutlineHome size={20} /></a>
                        <a to='/collections' className='breadcrumb-item text-black me-2'>Collections</a>
                        <a to={`/collections/${selectedVariant.collection.filter(collection => collection !== "All Products")}`} className='breadcrumb-item text-black me-2'>{selectedVariant.collection.filter(collection => collection !== "All Products")}</a>
                        <div className='breadcrumb-item text-black me-2'>{product_name}</div>
                    </div>
                </div>
                <div className="row gx-5">
                    <aside className="col-lg-6">
                        <div style={{ position: 'sticky', top: '18vh' }}>
                            <div className="border rounded-4 mb-3 d-flex justify-content-center">
                                <div className='d-none d-md-block d-lg-block'>
                                    <ReactImageMagnify {...{
                                        smallImage: {
                                            alt: 'Main Product',
                                            isFluidWidth: true,
                                            src: mainImage
                                        },
                                        largeImage: {
                                            src: mainImage,
                                            width: 1200,
                                            height: 1800
                                        },
                                        lensStyle: { backgroundColor: 'rgba(0,0,0,.6)' },
                                        shouldUsePositiveSpaceLens: true
                                    }} />
                                </div>
                                <div className='d-block d-md-none d-lg-none'>
                                    <img
                                        src={mainImage}
                                        alt="Main Product"
                                        style={{ width: '100%', height: 'auto' }}
                                    />
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mb-3">
                                {selectedVariant.images.map((image, index) => (
                                    <a
                                        key={index}
                                        data-fslightbox="mygalley"
                                        className="border mx-1 rounded-2"
                                        data-type="image"
                                        onClick={() => handleThumbnailClick(image)}
                                    >
                                        <img
                                            width={60}
                                            height={60}
                                            className="rounded-2"
                                            src={base_url + image}
                                            alt={`Thumbnail ${index}`}
                                        />
                                    </a>
                                ))}
                            </div>
                        </div>
                    </aside>
                    <main className="col-lg-6">
                        <div className="ps-lg-3">
                            <h4 className="title text-dark">
                                {selectedVariant.name}
                            </h4>
                            <div className="d-flex flex-row my-3">
                                <div className="text-warning mb-1 me-2">
                                    <IoStar />
                                    <span className="ms-1">{selectedVariant.price.find(p => p.weight + 'kg' === selectedSize)?.average_rating}</span>
                                </div>
                                <span className="text-muted">
                                    <i className="fas fa-shopping-basket fa-sm mx-1" />
                                    {selectedVariant.price.find(p => p.weight + 'kg' === selectedSize)?.delivered_sales_count} orders
                                </span>
                                <span className={`text-${selectedVariant.price.find(p => p.weight + 'kg' === selectedSize)?.inStock ? 'success' : 'danger'} mx-2`}>
                                    {selectedVariant.price.find(p => p.weight + 'kg' === selectedSize)?.inStock ? 'In stock' : 'Out of stock'}
                                </span>
                                {showLoadingIconWishlist ? (
                                    <AiOutlineLoading3Quarters size={20} color='#FF0000' className='ms-2 rotate' />
                                ) : isAddedToWishlist || selectedVariant.in_wishlist ? (
                                    <TbHeartCheck size={20} onClick={isAddedToWishlist || selectedVariant.in_wishlist ? handleRemoveFromWishlist : handleAddToWishlist} color='#FF0000' />
                                ) : (
                                    <TbHeartPlus size={20} onClick={isAddedToWishlist || selectedVariant.in_wishlist ? handleRemoveFromWishlist : handleAddToWishlist} color='#FF0000' />
                                )}
                            </div>
                            <div className="mb-3">
                                <div>
                                    <div>
                                        <span className="text-black text-decoration-line-through">Rs {selectedVariant.price.find(p => p.weight + 'kg' === selectedSize)?.basePrice || selectedVariant.price[0].basePrice}</span>
                                        <h4 className='text-black'>Rs {selectedVariant.price.find(p => p.weight + 'kg' === selectedSize)?.discountedPrice || selectedVariant.price[0].discountedPrice}
                                            <span className="badge abstext-danger text-danger ms-2 rounded-5" style={{ background: '#ff333352' }}>{DiscountNearestPercentage}%</span>
                                        </h4>                                        </div>
                                </div>
                            </div>
                            <h5 className='text-black mb-3'>Choose Size</h5>
                            <ButtonToolbar className="mb-4">
                                {selectedVariant.price.map((p, index) => (
                                    <Button
                                        key={index}
                                        variant={selectedSize === p.weight + 'kg' ? 'dark' : 'outline-dark'}
                                        onClick={() => handleSizeChange(p.weight + 'kg')}
                                        className="mx-1 rounded-5"
                                    >
                                        {p.weight}kg
                                    </Button>
                                ))}
                            </ButtonToolbar>

                            <h5 className='text-black mb-3'>Colors</h5>
                            <ButtonToolbar className="mb-4">
                                {item.map((item, index) => (
                                    <Button
                                        key={index}
                                        // variant={selectedSize === p.weight + 'kg' ? 'dark' : 'outline-dark'}
                                        onClick={() => handlevariantChange(item)}
                                        className="rounded-5 bg-transparent border-0 p-0"
                                    >
                                        <img
                                            className='product-image me-2'
                                            src={base_url + item?.images[0]}
                                            style={{
                                                zIndex: -1, position: 'relative', width: '5rem', border: selectedVariant.name === item.name ? '3px solid black' : ''
                                            }}
                                        />
                                    </Button>
                                ))}
                            </ButtonToolbar>
                            {/* <Link to={selectedVariant.price.find(p => p.weight + 'kg' === selectedSize)?.inStock ? `/checkout` : ''} state={{ item: selectedVariant, isBuyNow: 'yes', isCart: 'no' }}>
                                <button disabled={selectedVariant.price.find(p => p.weight + 'kg' === selectedSize)?.inStock ? false : true} className="btn btn-danger shadow-0 me-2 w-100" style={{ background: '#CC0000', color: 'white' }}>
                                    Buy now
                                </button>
                            </Link>
                            <button disabled={selectedVariant.price.find(p => p.weight + 'kg' === selectedSize)?.inStock ? false : true} className="btn shadow-0 w-50 me-1" style={{ background: '#CC0000', color: 'white' }} onClick={handleAddToCart}>
                                <i className="me-1 fa fa-shopping-basket" /> Add to cart
                            </button>
                            <button disabled={selectedVariant.price.find(p => p.weight + 'kg' === selectedSize)?.inStock ? false : true} className="btn shadow-0 w-25" style={{ background: '#CC0000', color: 'white' }} onClick={isAddedToWishlist || selectedVariant.in_wishlist ? handleRemoveFromWishlist : handleAddToWishlist}>
                                {showLoadingIconWishlist ? (
                                    <AiOutlineLoading3Quarters size={20} color='#EC1F29' className='rotate' />
                                ) : isAddedToWishlist || selectedVariant.in_wishlist ? (
                                    <TbHeartCheck size={20} color='#FFF' />
                                ) : (
                                    <TbHeartPlus size={20} color='#FFF' />
                                )}
                            </button> */}
                            <div className="quantity-selector mb-3 d-flex" style={{zIndex: "-1"}}>
                                <ButtonGroup className="me-3">
                                    <Button className='bg-black text-white border-0 rounded-start-2' onClick={() => handleQuantityChange(-1)}>
                                        <IoRemoveSharp />
                                    </Button>
                                    <span className="align-self-center bg-black text-white px-4 py-2 fw-bold">{quantity}</span>
                                    <Button className='bg-black text-white border-0 rounded-end-2' onClick={() => handleQuantityChange(1)}>
                                        <IoAddSharp />
                                    </Button>
                                </ButtonGroup>
                                <Button block variant="outline-dark" className='rounded-2 w-75' onClick={handleAddToCart}>Add to Cart</Button>
                            </div>
                            <Link to={selectedVariant.price.find(p => p.weight + 'kg' === selectedSize)?.inStock ? `/checkout` : ''} state={{ item: selectedVariant, isBuyNow: 'yes', isCart: 'no' }} >
                                <Button block disabled={selectedVariant.price.find(p => p.weight + 'kg' === selectedSize)?.inStock ? false : true} variant="primary" className='rounded-2 w-100' style={{ background: '#cc0001', border: '#cc0001' }}>Buy Now</Button>
                            </Link>
                            <hr style={{ zIndex: -1, position: 'relative' }} />
                            <div className="row">
                                <h6 className='title py-2 fs-5'>Product Details</h6>
                                <p className='text-black' dangerouslySetInnerHTML={{ __html: sanitizeHTML(selectedVariant.description) }} ></p>
                                <h6 className='title py-2 fs-5'>Warranty Details</h6>
                                {selectedVariant.warranty.map((item, index) => (
                                    <div key={index} className='mb-2'>
                                        <span><strong>{item.option}:</strong> {item.values.join(', ')}</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </main>
                </div>
            </div>
            <SweetAlert2 {...swalProps} />
            <RelatedProducts productCollection={selectedVariant.collection.filter(collection => collection !== "All Products")} />
            <RecentProducts />
        </div>
    );
}

export default ProductDetails;