import React, { useState } from 'react';
import { Container, Row, Col, FormControl, InputGroup, Form, Button } from 'react-bootstrap';
import { FaTwitter, FaFacebookF, FaInstagram } from 'react-icons/fa';
import logoImage from '../../assets/images/brand.png'; // Import your image file
import razorPay from '../../assets/images/razorpay.png';
import axios from 'axios';

const Footer = () => {
  const [emailInput, setEmailInput] = useState('');
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const handleInputChange = (e) => {
    setEmailInput(e.target.value);
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://karstar.org/api/client_api/subscribe/index.php', { email: emailInput });
      setMessage(response.data.message);
      console.log(response.data);
      setShowMessage(true);
      setEmailInput(''); // Clear the input field
    } catch (error) {
      setMessage(error.response?.data?.message || 'An error occurred');
      setShowMessage(true);
    }

    // Hide the message after 3 seconds
    setTimeout(() => {
      setShowMessage(false);
    }, 3000);
  };

  return (
    <footer>
      <div style={{ background: '#CC0001', padding: '2rem 1rem' }}>
        <Container>
          <Row className="text-start">
            <Col md={6} sm={12} className="d-flex align-items-center">
              <div className="mx-2">
                <h4 className="text-uppercase text-white fs-7">Subscribe to our Newsletter</h4>
                <p className="mb-0 text-white" style={{ fontSize: '0.8rem' }}>When new products, events and offers are launched you will be notified first!</p>
              </div>
            </Col>
            <Col md={6} sm={12} className="d-flex align-items-center justify-content-md-start justify-content-lg-end">
              <Form onSubmit={handleSubscribe}>
                <InputGroup>
                  <FormControl
                    style={{ border: '1px solid #6c757d', padding: '8px 10px' }}
                    aria-label="Enter your email"
                    placeholder="Enter your email"
                    value={emailInput}
                    onChange={handleInputChange}
                    type="email"
                    required
                  />
                  <Button type="submit" style={{ borderColor: '#000000', background: '#000000', textTransform: 'uppercase' }}>
                    Subscribe
                  </Button>
                </InputGroup>
                {showMessage && <p className="text-center mt-3">{message}</p>}
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bg-white py-4">
        <Container>
          <Row>
            <Col md={6}>
              <img
                src={logoImage}
                height="50"
                className="d-inline-block align-top"
                alt="Your Logo"
              />
              <p>
                Headoffice: Starlet Corporation <br />
                Ray Cel Complex, Devaraj URS Road, Opp. Vidyaniketana High School, <br />
                Saraswati Puram Main Road, Tumakuru, Karnataka 572105. <br />
              </p>
              <p>Support of Credit Cards and Payment Methods through Razorpay secure payment gateway</p>
              <img src={razorPay} alt="Payment Methods" className="img-fluid" />
            </Col>
            <Col md={2}>
              <h6>Company</h6>
              <ul className="list-unstyled">
              <li><a className='text-black' href="/">Home</a></li>
              <li><a className='text-black' href="/collections">Products</a></li>
              <li><a className='text-black' href="/aboutus">About Us</a></li>
              <li><a className='text-black' href="/contactus">Contact Us</a></li>
              <li><a className='text-black' href="/store-locator">Store Locator</a></li>
              <li><a className='text-black' href="/track-order">Orders Tracking</a></li>
              </ul>
            </Col>
            <Col md={2}>
              <h6>Useful Links</h6>
              <ul className="list-unstyled">
                <li><a className='text-black' href="/exchangereturnpolicy">Return & Exchange Policy</a></li>
                <li><a className='text-black' href="/cookiepolicy">Cookie Policy</a></li>
                <li><a className='text-black' href="/accessibilitypolicy">Accessibility Policy</a></li>
                <li><a className='text-black' href="/shippingpolicy">Shipping Policy</a></li>
                <li><a className='text-black' href="/communityguidelines">Community Guidelines</a></li>
                <li><a className='text-black' href="/copyrightnotice"> </a>Copyright Notice</li>
                <li><a className='text-black' href="/disclaimer">Disclaimer</a></li>
                <li><a className='text-black' href="/contactinformation">Contact Information</a></li>
              </ul>
            </Col>
            <Col md={2} className="text-md-end">
              <h6>Connect</h6>
              <p className='mb-0'>+91 9036 113 046</p>
              <p>info@hotstarr.co.in</p>
              <div className="d-flex justify-content-md-end">
                <FaTwitter className="me-3" size={20} />
                <FaFacebookF className="me-3" size={20} />
                <FaInstagram size={20} />
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md={6}>
              <p>© Karstar | Starlet Corporation LLP. 2024</p>
            </Col>
            <Col md={6} className="text-md-end">
              <p>
                <a href="/termsandcondtions" className="text-decoration-none text-black">Terms & Conditions</a> |
                <a href="/privacypolicy" className="text-decoration-none ms-2 text-black">Privacy Policy</a>
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <p className="text-muted text-center my-0" style={{ fontSize: '0.8rem' }}>Powered by w3builders Technology Solutions LLP</p>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
