import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import SecureLS from "secure-ls";
import CryptoJS from 'crypto-js';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState('');
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false); // Show/hide password toggle state
  const navigate = useNavigate();
  const ls = new SecureLS({ encodingType: 'aes' });
  const [loading, setLoading] = useState(true);
  // Extract the success message from the location state
  const [successMessage, setSuccessMessage] = useState(location.state?.successMessage);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const sessionId = sessionStorage.getItem('sessionId');

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      try {
        const response = await axios.post('https://karstar.org/api/client_api/authentication/login/index.php', {
          email,
          password,
          sessionId,
        });

        if (response.data.success) {
          ls.set('userEmail', email);
          ls.set('userName', response.data.name);
          // Retrieve the redirect path from sessionStorage
          const redirectPath = sessionStorage.getItem('redirectPath') || '/';

          // Clear the stored redirect path
          sessionStorage.removeItem('redirectPath');

          // Redirect to the stored path or the default path
          window.location.href = redirectPath;
        } else {
          setError(response.data.error || 'Login failed. Please check your credentials and try again.');
          setSuccessMessage('')
        }
      } catch (err) {
        console.error('Login failed:', err);
        setError('Login failed. Please check your credentials and try again.');
      }
    }

    setValidated(true);
  };

  const handleCreateAccountClick = () => {
    navigate('/auth/signup');
  };

  const toggleShowPassword = () => setShowPassword(!showPassword);

  const handleResendLink = async () => {
    setLoading(true); // Set loading to true at the beginning of the process

    try {
      // Hash the email using MD5
      const hashedEmail = CryptoJS.MD5(email).toString();

      const response = await axios.post('https://karstar.org/api/client_api/authentication/register/resendlink.php', {
        user_id: hashedEmail, // Send the hashed email
      });

      if (response.data.success) {
        setError('')
        setSuccessMessage(response.data.message + ' ' + 'Please check your email and click on the link to verify');
      } else {
        setError(response.data.message);
      }
    } catch (err) {
      console.error('Verification error:', err);
      setError('Error resending verification link. Please try again or contact support.');
    } finally {
      setLoading(false);
    }
  };


  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <Row className="w-100">
        <Col md={6} className="mx-auto">
          <h2 className="text-center mb-4">Login</h2>

          {/* Display the success message if it exists */}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}

          {error && <Alert variant="danger">{error}</Alert>}

          {error?.includes('Email not verified') &&
            <Button variant="outline-info" className="w-100 rounded-5 py-2 mb-3" onClick={handleResendLink}>
              Resend Verification Link
            </Button>
          }

          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Floating className="mb-3">
              <Form.Control
                required
                type="email"
                id="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Form.Label htmlFor="email">Email address</Form.Label>
              <Form.Control.Feedback type="invalid">
                Please provide a valid email.
              </Form.Control.Feedback>
            </Form.Floating>

            <Form.Floating className="mb-3 position-relative">
              <Form.Control
                required
                type={showPassword ? 'text' : 'password'}
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                minLength={8}
              />
              <Form.Label htmlFor="password">Password</Form.Label>
              <Form.Control.Feedback type="invalid">
                Password must be at least 8 characters long and include letters, numbers, and special characters.
              </Form.Control.Feedback>
              <span onClick={toggleShowPassword} className="position-absolute end-0 top-50 translate-middle-y me-5">
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </Form.Floating>

            <div className='d-flex justify-content-end align-items-center mb-3'>
              {/* <Form.Group controlId="formBasicCheckbox">
                <Form.Check type="checkbox" label="Remember me" />
              </Form.Group> */}

              <div className="text-end">
                <Link to="/auth/reset" className="text-primary">Forgot password?</Link>
              </div>
            </div>

            <Button variant="danger" type="submit" className="rounded-5 py-2 w-100 mb-3">
              Sign in
            </Button>

            <Button variant="outline-dark" className="rounded-5 py-2 w-100 mt-3" onClick={handleCreateAccountClick}>
              Create account
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
