import React from 'react'

function Stores() {
  return (
    <div>
      Stores
    </div>
  )
}

export default Stores
