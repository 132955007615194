import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button, Container, Dropdown, DropdownButton, Form, FormControl, InputGroup } from 'react-bootstrap'
import { IoSearchOutline } from 'react-icons/io5'
import './Search.css'
import { useNavigate } from 'react-router-dom';
function Search() {

    const [iconColor, setIconColor] = useState('white'); // State to manage icon color
    const [searchInput, setSearchInput] = useState(''); // State to manage search input

    const [categories, setCategories] = useState([])
    const baseUrl = 'https://karstar.org/'

    useEffect(() => {
        getCategories();
    }, [])

    const getCategories = async () => {
        try {
            const response = await axios.get('https://karstar.org/api/client_api/productCategories/index.php');
            setCategories(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleInputChange = (e) => {
        setSearchInput(e.target.value); // Update searchInput state with input value
    };

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (searchInput != '') {
            navigate(`/search/${searchInput}`, {
                state: {
                    searchInput
                }
            });
        }
    };

    return (
        <div>
            <Container>
                <section className='search-bar-home d-lg-none mt-2'>
                    <Form onSubmit={handleSubmit}>
                        <InputGroup>
                            <FormControl
                                style={{ border: '1px solid #CC0001', padding: '12px 10px', width: '10rem', color: '#CC0001' }}
                                aria-label="Search Products"
                                placeholder="Search Products"
                                value={searchInput}
                                onChange={handleInputChange}
                            />
                            <Button style={{ borderColor: '#CC0001', borderWidth: '1px', background: '#CC0001' }}
                                onMouseEnter={() => setIconColor('white')}
                                onMouseLeave={() => setIconColor('white')}
                                type='submit'
                                className='d-flex justify-content-center align-items-center'
                            >
                                <IoSearchOutline
                                    size={'20px'}
                                    color={iconColor}
                                    className="icon searchIcon"
                                />
                                Search
                            </Button>
                        </InputGroup>
                    </Form>
                </section>
            </Container>
        </div>
    )
}

export default Search
