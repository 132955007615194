import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import './Home.css';
import Header from '../../components/Header/Header';
import Sliders from '../../components/Sliders/Sliders';
import Categories from '../../components/Categories/Categories';
import RecentProducts from '../../components/Products/RecentProducts';
import PopularProducts from '../../components/Products/PopularProducts';
import Showcase from '../../components/Showcase/Showcase';
import FeaturedProducts from '../../components/Products/FeaturedProducts';
import Deals from '../../components/Products/Deals';
import OfferProducts from '../../components/Products/OfferProducts';
import Features from '../../components/Features/Features';
import Search from '../../components/Search/Search'
import { useNavigate } from 'react-router-dom';
const components = [
  { component: <Search key="Search" />, key: 'Search' },
  { component: <Categories key="Categories" />, key: 'Categories' },
  { component: <Sliders key="Sliders" />, key: 'Sliders' },
  { component: <RecentProducts key="RecentProducts" />, key: 'RecentProducts' },
  // { component: <PopularProducts key="PopularProducts" />, key: 'PopularProducts' },
  // { component: <Showcase key="Showcase" />, key: 'Showcase' },
  { component: <FeaturedProducts key="FeaturedProducts" />, key: 'FeaturedProducts' },
  { component: <Features key="Features" />, key: 'Features' },
  { component: <Deals key="Deals" />, key: 'Deals' },
  { component: <OfferProducts key="OfferProducts" />, key: 'OfferProducts' },
  // Uncomment if you want to include AppIntro
  // { component: <AppIntro key="AppIntro" />, key: 'AppIntro' }
];

function Home() {


  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToTop();
  }, [navigate]);

  return (
    <div className='sectionView'>
      {components.map(({ component, key }) => (
        <div key={key} data-key={key}>
          {component}
        </div>
      ))}
    </div>
  );
}

export default Home;
