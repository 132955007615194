import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Policies.css'; // Custom CSS for the font style

const CopyrightNotice = () => {
  return (
    <Container className="copyright-notice sectionView">
      <Row>
        <Col>
          <h1 className="text-center">Copyright Notice</h1>
          <p className="text-center"><em>Last updated: 1st September 2024</em></p>
          
          <h2>1. Ownership of Content</h2>
          <p>All content on Karstar ("we," "us," "our"), including but not limited to text, images, logos, and graphics, is protected by copyright law. We hold the copyright to this content unless otherwise stated.</p>
          
          <h2>2. Use of Content</h2>
          <h3>a. Permissions</h3>
          <p>You may not reproduce, distribute, or use our content for commercial purposes without our express written permission.</p>
          
          <h3>b. Seeking Permission</h3>
          <p>If you wish to use our content, please contact us to request permission.</p>
          
          <h2>3. Protection of Rights</h2>
          <p>We will take legal action against any unauthorized use or infringement of our copyright.</p>
          
          <h2>4. Contact Us</h2>
          <p>If you have any questions or concerns about our Copyright Notice, please contact us from the contact us page.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default CopyrightNotice;
