import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Slider from 'react-slick'
import './Categories.css'
import { Link } from 'react-router-dom';
function Categories() {

    const categoriesSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 8,
        autoplay: false,
        autoplaySpeed: 2000,
        arrows: false,
        swipe: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 8,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 8,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5,
                },
            },
        ],
    };

    const [categories, setCategories] = useState([])
    const [loading, setLoading] = useState(true);
    const baseURL = 'https://karstar.org/'
    useEffect(() => {
        getCategories();
    }, [])

    const getCategories = async () => {
        try {
            const response = await axios.get(`${baseURL}/api/client_api/productCategories/index.php`);
            setCategories(response.data);
            setLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    const generateSlug = (name) => {
        return name.replace(/ /g, '-').replace(/[^\w-]+/g, '');
    }

    return (
        <div>
            <section className='categoriesView' >
                <Container>
                    <Row className="mt-3">
                        <Col>
                            {/* <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <h4 className='title'>Shop by Categories</h4>
                                </div>
                                <div>
                                    <a href="/collections" className='text-black'>See All</a>
                                </div>
                            </div> */}
                            {loading ? (
                                <div>Loading...</div>
                            ) : (
                                <Slider {...categoriesSettings} className="top-categories pt-2">
                                    {categories
                                        .slice() // Create a shallow copy of the array to avoid mutating the original array
                                        .sort((a, b) => a.name.localeCompare(b.name)) // Sort the categories alphabetically by name
                                        .map((category, index) => (
                                            <a
                                                key={index}
                                                href={`/collections/${generateSlug(category.name)}`}
                                                state={{ category }}
                                                className="text-decoration-none text-black"
                                            >
                                                <Col className="px-2 d-flex justify-content-center align-items-center flex-column">
                                                    <div
                                                        variant="outline-secondary"
                                                        className="d-flex justify-content-center align-items-center bg-light categoriesContainer"
                                                    >
                                                        <img
                                                            src={baseURL + category.image_path}
                                                            alt={category.name}
                                                            className="categoriesImage"
                                                        />
                                                    </div>
                                                    <p className="text-center mt-2 categoriesText">
                                                        {category?.name?.replace(/-/g, " ")}
                                                    </p>
                                                </Col>
                                            </a>
                                        ))}
                                </Slider>
                            )}
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default Categories
