import React, { useEffect } from 'react';

class Util {
  extend(custom, defaults) {
    for (let key in custom) {
      let value = custom[key];
      if (value != null) {
        defaults[key] = value;
      }
    }
    return defaults;
  }

  isMobile(agent) {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(agent);
  }
}

class WOW {
  constructor(options = {}) {
    this.scrollCallback = this.scrollCallback.bind(this);
    this.scrollHandler = this.scrollHandler.bind(this);
    this.start = this.start.bind(this);
    this.scrolled = true;
    this.config = this.util().extend(options, this.defaults);
  }

  defaults = {
    boxClass: 'wow',
    animateClass: 'animated',
    offset: 0,
    mobile: true,
  };

  init() {
    this.element = window.document.documentElement;
    if (document.readyState === "interactive" || document.readyState === "complete") {
      this.start();
    } else {
      document.addEventListener('DOMContentLoaded', this.start);
    }
  }

  start() {
    this.boxes = this.element.getElementsByClassName(this.config.boxClass);
    if (this.boxes.length) {
      if (this.disabled()) {
        this.resetStyle();
      } else {
        for (let box of this.boxes) {
          this.applyStyle(box, true);
        }
        window.addEventListener('scroll', this.scrollHandler, false);
        window.addEventListener('resize', this.scrollHandler, false);
        this.interval = setInterval(this.scrollCallback, 50);
      }
    }
  }

  stop() {
    window.removeEventListener('scroll', this.scrollHandler, false);
    window.removeEventListener('resize', this.scrollHandler, false);
    if (this.interval != null) {
      clearInterval(this.interval);
    }
  }

  show(box) {
    this.applyStyle(box);
    box.className = `${box.className} ${this.config.animateClass}`;
  }

  applyStyle(box, hidden) {
    let duration = box.getAttribute('data-wow-duration');
    let delay = box.getAttribute('data-wow-delay');
    let iteration = box.getAttribute('data-wow-iteration');
    box.setAttribute('style', this.customStyle(hidden, duration, delay, iteration));
  }

  resetStyle() {
    for (let box of this.boxes) {
      box.setAttribute('style', 'visibility: visible;');
    }
  }

  customStyle(hidden, duration, delay, iteration) {
    let style = hidden
      ? "visibility: hidden; -webkit-animation-name: none; -moz-animation-name: none; animation-name: none;"
      : "visibility: visible;";
    if (duration) {
      style += `-webkit-animation-duration: ${duration}; -moz-animation-duration: ${duration}; animation-duration: ${duration};`;
    }
    if (delay) {
      style += `-webkit-animation-delay: ${delay}; -moz-animation-delay: ${delay}; animation-delay: ${delay};`;
    }
    if (iteration) {
      style += `-webkit-animation-iteration-count: ${iteration}; -moz-animation-iteration-count: ${iteration}; animation-iteration-count: ${iteration};`;
    }
    return style;
  }

  scrollHandler() {
    this.scrolled = true;
  }

  scrollCallback() {
    if (this.scrolled) {
      this.scrolled = false;
      this.boxes = Array.from(this.boxes).filter((box) => {
        if (this.isVisible(box)) {
          this.show(box);
          return false;
        }
        return true;
      });
      if (!this.boxes.length) {
        this.stop();
      }
    }
  }

  offsetTop(element) {
    let top = element.offsetTop;
    while (element = element.offsetParent) {
      top += element.offsetTop;
    }
    return top;
  }

  isVisible(box) {
    let offset = box.getAttribute('data-wow-offset') || this.config.offset;
    let viewTop = window.pageYOffset;
    let viewBottom = viewTop + this.element.clientHeight - offset;
    let top = this.offsetTop(box);
    let bottom = top + box.clientHeight;
    return top <= viewBottom && bottom >= viewTop;
  }

  util() {
    return this._util || (this._util = new Util());
  }

  disabled() {
    return !this.config.mobile && this.util().isMobile(navigator.userAgent);
  }
}

const useWOW = (options) => {
  useEffect(() => {
    const wow = new WOW(options);
    wow.init();
    return () => {
      wow.stop();
    };
  }, [options]);
};

const WOWComponent = ({ options, children }) => {
  useWOW(options);
  return <>{children}</>;
};

export default WOWComponent;
