import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import ProductCard from '../../components/ProductCard/ProductCard';

function Search() {
    const location = useLocation();
    const { searchInput: stateSearchInput } = location.state || {};
    const { searchInput: urlSearchInput } = useParams();
    const searchInput = stateSearchInput || urlSearchInput || '';
    const baseUrl = 'https://karstar.org/';

    const [products, setProducts] = useState([]);
    console.log(products);

    const getSearchProducts = async (searchValue) => {
        try {
            const response = await axios.get(`https://karstar.org/api/client_api/search/index.php?searchValue=${searchValue}`);
            setProducts(response.data); // Update state with fetched products
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (searchInput) {
            getSearchProducts(searchInput);
        }
    }, [searchInput]);

    return (
        <div>
            <Container>
                <section className='pb-5 sectionView'>
                    <Row className="g-0">
                        <h2 className='title text-center py-5' style={{ fontSize: '2rem' }}>
                            Showing Results for {searchInput}
                        </h2>
                        {products && products.map((item, index) => (
                            <Col xs={6} md={6} lg={2} key={index}>
                                <ProductCard item={item} baseUrl={baseUrl} />
                            </Col>
                        ))}
                    </Row>
                </section>
            </Container>
        </div>
    );
}

export default Search;
