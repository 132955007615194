import React from 'react'
import './Aboutus.css';
import aboutus from '../../assets/images/aboutus.png'
function Aboutus() {
  return (
    <div className='sectionView container mt-3'>
        <div className='breadcrumb-container'>
          <div className='breadcrumb'>
            <a href='/' className='breadcrumb-item text-black'>Home</a>
            <div className='breadcrumb-item text-black'>About Us</div>
          </div>
        </div>
      <section className="bg-img1 text-center p-lr-15 p-b-10 p-t-95 container">
        <h2 className="ltext-103 text-center cl4" style={{ margin: '0 15px 30px 15px' }}>About Us</h2>
      </section>
      <section className="p-t-5 p-b-120">
        <div className="container">
          <div className="row pb-5">
            <div className="col-md-7 col-lg-8">
              <div className="p-t-7 p-r-85 p-r-15-lg p-r-0-md">
                <h3 className="mtext-111 cl2 p-b-16">
                  Our Story
                </h3>

                <p className="stext-113 cl6 p-b-26">

                </p>

                <p className="stext-113 cl6 p-b-26">

                </p>

                <p className="stext-113 cl6 p-b-26">
                  Any questions? call or WhatsApp us on <span className="fw-bold">(+91) 9036113046 </span> <br />
                  <span className="fw-bold">Headoffice </span>: Starlet Corporation <br />
                  Ray Cel Complex, Devaraj URS Road, Opp. Vidyaniketana High School, Saraswati Puram Main Road
                  Tumakuru, Karnataka 572105.
                </p>
              </div>
            </div>

            <div className="col-11 col-md-5 col-lg-4 m-lr-auto">
              <div className="border-1">
                <div className="border-1">
                  <img src={aboutus} alt="IMG" className='w-100' />
                </div>
              </div>
            </div>
          </div>
          <div className="row py-5">
            <div className="order-md-2 col-md-7 col-lg-8 p-b-30">
              <div className="p-t-7 p-l-85 p-l-15-lg p-l-0-md">
                <h3 className="mtext-111 cl4 p-b-16">
                  Our Mission
                </h3>

                <p className="stext-113 cl6 p-b-26">
                  Our motto is to make your life easy by delivering best quality innovative products at affordable pricing.
                </p>

                <div className="bor16 p-l-29 p-b-9 m-t-22">
                  <p className="stext-114 cl6 p-r-40 p-b-11">
                    Creativity is just connecting things. When you ask creative people how they did something, they feel a little guilty because they didn't really do it, they just saw something. It seemed obvious to them after a while.
                  </p>

                  <span className="stext-111 cl8">
                    - Steve Job’s
                  </span>
                </div>
              </div>
            </div>

            <div className="order-md-1 col-11 col-md-5 col-lg-4 m-lr-auto p-b-30">
              <div className="how-bor2">
                <div className="hov-img0">
                  <img src={aboutus} alt="IMG" className='w-100' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Aboutus
