import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './Policies.css'; // Custom CSS for the font style

const ContactInformation = () => {
  return (
    <Container className="contact-information sectionView">
      <Row>
        <Col>
          <h1 className="text-center">Contact Information</h1>
          
          <p>If you need to reach us for any reason, please use the following contact information:</p>
          
          <h2>Customer Support</h2>
          <p><strong>Email:</strong> <a href="mailto:info@Karstar.co.in">info@Karstar.co.in</a></p>
          <p><strong>Phone:</strong> <a href="tel:+919036113046">9036113046</a></p>
          
          <h2>Mailing Address</h2>
          <address>
            Starlet Corporation<br />
            Ray Cel Complex, Devaraj URS Road, Opp. Vidyaniketana High School,<br />
            Saraswati Puram Main Road, Tumakuru, Karnataka 572105.<br />
          </address>
          
          <h2>Business Hours</h2>
          <p>09:00 AM to 07:00 PM</p>
          
          <h2>Website</h2>
          <p><a href="https://Karstar.co.in" target="_blank" rel="noopener noreferrer">https://Karstar.co.in</a></p>
          
          <p>For specific inquiries or to contact us regarding policies or other matters, please refer to the relevant policy for contact details.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactInformation;
