import React, { useState, useEffect, useContext } from 'react';
import { Container, Navbar, Nav, Form, FormControl, InputGroup, Dropdown, DropdownButton, Button, Badge, Row, Col } from 'react-bootstrap';
import './Header.css';
import { IoCalendarClearOutline, IoCartOutline, IoHeartOutline, IoPersonOutline, IoSearchOutline } from "react-icons/io5";
import logoImage from '../../assets/images/brand.png';
import logoImageHotstarr from '../../assets/images/starr.png';
import { CartContext } from '../../Utils/CartContext';
import { WishlistContext } from '../../Utils/WishlistContext';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { FaCalendar, FaFacebook, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import { TbBrandGoogleMaps } from 'react-icons/tb';
import { AiFillYoutube, AiOutlineMail, AiOutlineWhatsApp } from 'react-icons/ai';
import { TfiHeadphoneAlt } from 'react-icons/tfi';
import WOWComponent from '../../Utils/WOW';
import SecureLS from "secure-ls";

function Header() {
    const { cartCount, cartTotal } = useContext(CartContext);
    const { wishlistCount } = useContext(WishlistContext); // Use wishlist context

    const [iconColor, setIconColor] = useState('white');
    const [searchInput, setSearchInput] = useState('');

    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const [lastScrollTop, setLastScrollTop] = useState(0);

    const handleInputChange = (e) => {
        setSearchInput(e.target.value);
    };

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (searchInput != '') {
            navigate(`/search/${searchInput}`, {
                state: {
                    searchInput
                }
            });
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollTop = window.pageYOffset;
            if (currentScrollTop > lastScrollTop && currentScrollTop > 1 * window.innerHeight / 100) {
                setIsNavbarVisible(false);
            } else {
                setIsNavbarVisible(true);
            }
            setLastScrollTop(currentScrollTop);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollTop]);

    const [categories, setCategories] = useState([])
    const baseUrl = 'https://karstar.org/'

    useEffect(() => {
        getCategories();
    }, [])

    const getCategories = async () => {
        try {
            const response = await axios.get('https://karstar.org/api/client_api/productCategories/index.php');
            setCategories(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const generateSlug = (name) => {
        return name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
    }


    const adjustPadding = () => {
        const header = document.querySelector('.header'); // Adjust the selector as needed
        const section = document.querySelector('.sectionView');

        if (header && section) {
            const headerHeight = header.offsetHeight;
            section.style.paddingTop = `${headerHeight + 16}px`; // Adjust the additional padding as needed
        }
    };

    useEffect(() => {
        adjustPadding();
        window.addEventListener('resize', adjustPadding);

        return () => window.removeEventListener('resize', adjustPadding);
    }, []);

    // Initialize SecureLS
    const ls = new SecureLS({ encodingType: 'aes' });

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userName, setUserName] = useState('');

    useEffect(() => {
        // ls.removeAll();
        // Retrieve the stored email and name using SecureLS
        const email = ls.get('userEmail');
        const name = ls.get('userName');

        if (email && name) {
            setIsLoggedIn(true);
            setUserName(name);
        }
    }, []);


    const SignInLink = () => {
        const location = useLocation();

        const handleSignInClick = () => {
            // Store the current path in sessionStorage
            sessionStorage.setItem('redirectPath', location.pathname);
        };

        return (
            <Link
                to='/auth/login'
                className='text-decoration-none text-black d-flex align-items-center justify-content-center'
                onClick={handleSignInClick} // Call this function on click
            >
                <div className="icon-text-container">
                    <IoPersonOutline size={'22px'} color='#000' className="icon" />
                    <div className='d-flex flex-column'>
                        <span className="text-small text-start">Sign in</span>
                        <span className="text">Guest user</span>
                    </div>
                </div>
            </Link>
        );
    };


    const UserDropdown = () => {
        return (
            // <div className="dropdown d-flex">
            //     <div className="icon-text-container" style={{ cursor: 'pointer' }}>
            //         <IoPersonOutline size={'22px'} color='#000' className="icon" />
            //     </div>
            //     <div className="dropdown-content">
            //         <a href="#" className="text-center text-danger">{userName}</a>
            //         <hr style={{ margin: '0' }} className="text-danger" />
            //         <div className="dropdown-options">
            //             <a href="myprofile">
            //                 <i className="fa-solid fa-user blue"></i>
            //                 My Profile
            //             </a>
            //         </div>
            //         <div className="dropdown-options">
            //             <a href="myorders">
            //                 <i className="fa-solid fa-cart-shopping blue"></i>
            //                 My Orders
            //             </a>
            //         </div>
            //         <div className="dropdown-options">
            //             <a href="myaddress">
            //                 <i className="fas fa-map-marker-alt blue"></i>
            //                 Manage Addresses
            //             </a>
            //         </div>
            //         <div className="dropdown-options">
            //             <a href="" data-bs-toggle="modal" data-bs-target="#confirmlogoutmodal">
            //                 <i className="fa fa-sign-out blue" aria-hidden="true"></i>
            //                 Logout
            //             </a>
            //         </div>
            //     </div>
            // </div>
            <Link
                to='/account'
                className='text-decoration-none text-black d-flex align-items-center justify-content-center'
            >
                <div className="icon-text-container">
                    <IoPersonOutline size={'22px'} color='#000' className="icon" />
                </div>
            </Link>
        );
    };

    return (
        <WOWComponent options={{ animateClass: 'animated', offset: 50 }}>
            <header className='custom-header '>
                <div style={{ borderBottom: "1px solid #DDDDDD" }} className={`py-0 header-top menu-navbar ${isNavbarVisible ? '' : 'hidden'}`}>
                    <Container>
                        <Row className="align-items-center">
                            <Col xs={12} lg={4} sm={4} md={3} className="p-0">
                                <div className="social-media d-flex">
                                    {/* <a
                                        className="px-2 text-decoration-none"
                                        href="https://api.whatsapp.com/send/?phone=919036113046&text&type=phone_number&app_absent=0"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaWhatsapp color="#CC0001" size={18} />
                                    </a> */}
                                    <a
                                        className="px-2 text-decoration-none"
                                        href="https://www.facebook.com/people/Hotstarr/100076573480758/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaFacebook color="#CC0001" size={18} />
                                    </a>
                                    <a
                                        className="px-2 text-decoration-none"
                                        href="https://www.instagram.com/Hotstarr2022/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FaInstagram color="#CC0001" size={18} />
                                    </a>
                                    <a
                                        className="px-2 text-decoration-none"
                                        href="https://maps.app.goo.gl/g31bRXvAch62bMbC8"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <TbBrandGoogleMaps color="#CC0001" size={18} />
                                    </a>
                                    <a
                                        className="px-2 text-decoration-none"
                                        href="https://www.youtube.com/channel/UCqRFzmq55EPke8gUOcqqarA/featured"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <AiFillYoutube color="#CC0001" size={18} />
                                    </a>
                                </div>
                            </Col>
                            <Col xs={12} lg={8} sm={8} md={9} className="p-0 d-flex justify-content-end">
                                <div className="d-flex flex-row align-items-center p-2">
                                    <IoCalendarClearOutline color="#CC0001" size={15} />
                                    <p className="text-start text-capitalize mb-0 ms-1 header-top-text">Since 2016</p>
                                </div>
                                <div className="d-flex flex-row align-items-center p-2">
                                    <AiOutlineMail color="#CC0001" size={15} />
                                    <p className="text-start mb-0 ms-1 header-top-text" >
                                        <a
                                            href="mailto:info@hotstarr.co.in"
                                            className="text-decoration-none text-black"
                                        >
                                            info@hotstarr.co.in
                                        </a>
                                    </p>
                                </div>
                                <div className="d-flex flex-row align-items-center p-2">
                                    <TfiHeadphoneAlt color="#CC0001" size={15} />
                                    <p className="text-start mb-0 ms-1 header-top-text"
                                    >
                                        <a
                                            href="tel:9036113046"
                                            className="text-decoration-none text-black"
                                        >
                                            (+91) 888 485 5855
                                        </a>
                                    </p>
                                </div>
                                <div className="d-flex flex-row align-items-center p-2 header-top-text">
                                    <AiOutlineWhatsApp color="#CC0001" size={15} />
                                    <p className="text-start text-small mb-0 ms-1 header-top-text">
                                        <a
                                            href="https://api.whatsapp.com/send/?phone=919036113046&text&type=phone_number&app_absent=0"
                                            className="text-decoration-none text-black"
                                        >
                                            (+91) 903 611 3046
                                        </a>
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className={`top-header`}>
                    <Container className="d-flex align-items-center">
                        <div className="logo" style={{ flexBasis: '10%' }}>
                            <Navbar.Brand>
                                <Link to="/">
                                    <img
                                        src={logoImage}
                                        width="auto"
                                        height="50"
                                        className="d-inline-block align-top"
                                        alt="Karstar"
                                    />
                                </Link>
                                <img
                                    src={logoImageHotstarr}
                                    width="auto"
                                    height="15"
                                    className="d-inline-block align-top"
                                    alt="Hotstarr"
                                />
                            </Navbar.Brand>
                        </div>
                        <div className="d-flex align-items-center justify-content-end" style={{ flexBasis: '90%' }}>
                            <div className="search-bar d-none d-lg-block">
                                <Form onSubmit={handleSubmit}>
                                    <InputGroup>
                                        <FormControl
                                            style={{ border: '1px solid #000', padding: '8px 10px', width: '20rem', color: '#CC0001' }}
                                            aria-label="Search Products"
                                            placeholder="Search Products"
                                            value={searchInput}
                                            onChange={handleInputChange}
                                        />
                                        <Button style={{ borderColor: '#000', borderWidth: '1px', background: '#CC0001' }}
                                            onMouseEnter={() => setIconColor('white')}
                                            onMouseLeave={() => setIconColor('white')}
                                            type='submit'
                                            className='d-flex justify-content-center align-items-center'
                                        >
                                            <IoSearchOutline
                                                size={'20px'}
                                                color={iconColor}
                                                className="icon searchIcon"
                                            />
                                            Search
                                        </Button>
                                    </InputGroup>
                                </Form>
                            </div>
                            <div className='d-flex'>
                                {isLoggedIn ? <UserDropdown /> : <SignInLink />}
                                <Link to='Wishlist' className='text-decoration-none d-flex align-items-center justify-content-center'>
                                    <div className="icon-text-container">
                                        <IoHeartOutline size={'22px'} color='#000' className="icon" />
                                        <span className='icon-badge wishlist-badge'> {wishlistCount} </span>
                                    </div>
                                </Link>
                                <Link to='Cart' className='text-decoration-none d-flex align-items-center justify-content-center'>
                                    <div className="icon-text-container">
                                        <IoCartOutline size={'22px'} color='#000' className="icon" />
                                        <span className='icon-badge cart-badge'> {cartCount} </span>
                                    </div>
                                </Link>
                                <div className="icon-text-container">
                                    <div className='d-flex flex-column'>
                                        <span className="text-small text-start">Cart total</span>
                                        <span className="text fw-bold">₹ {cartTotal}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>

                </div>
                <Navbar style={{ background: '#CC0001', height: 'auto', zIndex: -1 }} className={`menu-navbar py-0 my-0 ${isNavbarVisible ? '' : 'hidden'}`}>
                    <Container>
                        <Navbar id="basic-navbar-nav  py-0 my-0" style={{ height: 'auto' }}>
                            <Nav className="me-auto text-white">
                                <Link to="/" className="nav-link text-white fw-light me-2 bottomNavbarCategories  py-0 my-0">Home</Link>
                                <Link to="/collections" className="nav-link text-white fw-light me-2 bottomNavbarCategories  py-0 my-0">Products</Link>
                                <Link to="/aboutus" className="nav-link text-white fw-light me-2 bottomNavbarCategories  py-0 my-0">About Us</Link>
                                <Link to="/contactus" className="nav-link text-white fw-light me-2 bottomNavbarCategories py-0 my-0">Contact Us</Link>
                                <Link to="/store-locator" className="nav-link text-white fw-light me-2 bottomNavbarCategories py-0 my-0">Store Locator</Link>
                                <Link to="/track-order" className="nav-link text-white fw-light me-2 bottomNavbarCategories py-0 my-0">Track Order</Link>
                            </Nav>
                        </Navbar>
                    </Container>
                </Navbar>
            </header>
        </WOWComponent>
    );
}

export default Header;
