import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Badge } from 'react-bootstrap';
import { IoAddCircleOutline, IoStar, IoStarOutline } from 'react-icons/io5';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Products.css'
import ProductCard from '../ProductCard/ProductCard';

function Deals() {
    const productsSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        adaptiveHeight: false,
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const [products, setProducts] = useState([])
    const baseUrl = 'https://karstar.org/'
    const [loading, setLoading] = useState(true); // Add a loading state

    useEffect(() => {
        const sessionId = sessionStorage.getItem('sessionId');
        const username = sessionStorage.getItem('username');
        if (username == null) {
            if (sessionId !== null) {
                getDealsProd(sessionId, 'na');
            }
        } else {
            getDealsProd('na', username);
        }
    }, []);

    const getDealsProd = async (sessionId, username) => {
        try {
            const response = await axios.get(`https://karstar.org/api/client_api/Deals/index.php?category=Geysers&ipadd=${sessionId}&email=${username}`);
            setProducts(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false); // Set loading to false after the fetch is complete
        }
    };


    return (
        <div>
            <Container>
                <section className='pt-1' >
                    <Row className="g-0">
                        <Col>
                            <h4 className='title py-2'>Deals On  Pressure Washers</h4>
                            {loading ? (
                                <div>Loading...</div> // Display a loading message or spinner
                            ) : (
                                <Slider {...productsSettings} className="top-categories pt-2">
                                    {products.map((item, index) => (
                                        <ProductCard key={index} item={item} baseUrl={baseUrl} wishlist={item.in_wishlist} cart={item.in_cart} />
                                    ))}
                                </Slider>
                            )}
                        </Col>
                    </Row>
                </section>
            </Container>
        </div>
    )
}

export default Deals
